<template>
  <v-autocomplete
    :value="value"
    dense
    outlined
    label="Estado"
    :items="estados"
    item-text="descricao"
    item-value="id"
    :loading="carregando"
    :disabled="carregando"
    :error-messages="errorMessages"
    autocomplete="nope"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
  import estadosApi from '@/api/estados'

  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      errorMessages: {
        type: Array,
        default: () => {},
      },
    },

    data () {
      return {
        estados: [],
        carregando: false,
      }
    },

    mounted () {
      this.buscarEstados()
    },

    methods: {

      async buscarEstados () {
        try {
          this.carregando = true

          const resposta = await estadosApi.listar()

          this.estados = resposta.data
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },

  }
</script>
